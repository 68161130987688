import posthog from "posthog-js";
import { POSTHOG_CONSTANTS as constants } from "./constants";

const isTestProd = ["test", "production"].includes(
  process.env.REACT_APP_CUSTOM_NODE_ENV
);

// const isTestProd = true

export const init = () => {
  const options = {
    api_host: constants.API_HOST,
  };
  if(isTestProd) {
    posthog.init(constants.POSTHOG_KEY, options);
  }
};

export const trackLogin = (email) => {
  if (isTestProd) {
    captureEvent("Login", { email });
  }
}

export const identify = (email) => {
  if (isTestProd) {
    posthog.people.set({ email });
    posthog.identify(email);
    trackLogin(email)
  }
};

export const captureEvent = (event, prop) => {
  if (isTestProd) {
    posthog.capture(event, prop);
  }
};

export const trackPageView = (email) => {
  const path = window.location.href;
  if (isTestProd) {
    captureEvent("$pageview", { path });
  }
};

export const reset = () => {
  if (isTestProd) {
    posthog.reset();
  }
};
